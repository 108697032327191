import {Button, ChDropdown, ChSearch, Switch, Timepicker} from "ch3-ui-lib";
import {useEffect, useState} from "react";
import {convertAmPmToIso, convertIsoToAmPm, weekDays} from "../../utilis/timeUtilis";
import {WorkingHoursInterface,
    getMsisdnSettingsWorkingHours, updateMsisdnSettingsWorkingHours,
} from "../../store/features/MsisdnSettings/msisdnSettingsSlice";
import {RootState, useAppDispatch} from "../../store/store";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {capitalize} from "lodash";
import {useNavigate} from 'react-router-dom';
import {ClosedDialerMargin, OpenDialerMargin} from "../../utilis/dialerWidthUtils.ts";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";

const WorkingHours = () => {
    const navigate = useNavigate();
    const {id} = useParams<{ id: string }>();
    const isMobile = useMediaQuery('(max-width: 960px)');

    const dispatch = useAppDispatch();
    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);


    useEffect(() => {
        const fetchData = async () => {

            await dispatch(getMsisdnSettingsWorkingHours(id));

            // dispatch(getMsisdnSettingsCallRules(id));
        }
        fetchData();
    }, [id]);

    const workingHoursData = useSelector((state: RootState) => state.msisdnSettings.workingHours);
    const [formData, setFormData] = useState({} as WorkingHoursInterface);
    const [errors, setErrors] = useState<any[]>([]);



    const timezoneList = Intl.supportedValuesOf('timeZone').map((timezone: string) => {
        return {
            key: timezone,
            value: timezone,
            label: timezone.replace(/_/g, ' ')
        }
    });
    const initialTimezone =  timezoneList.find((timezone) => timezone.key === 'America/New_York');

    useEffect(() => {
        if (workingHoursData) {
            const data = {
                ...workingHoursData,
                timeZone: timezoneList.find((timezone) => timezone.key === workingHoursData.timeZone) ?? initialTimezone,
                workingHours: getWorkingHours(),
            };
            setFormData(data)
        };
    }, [id, workingHoursData]);

    const handleScheduleTypeChange = (value: string) => {
        setErrors([]);
        setFormData(getDaysHoursSettings(value));
    }

    const getWorkingHours = () => {
        if(!workingHoursData) return [];
        if(!workingHoursData.workingHours.length) return getDaysHoursSettings(workingHoursData.type).workingHours;
       return  workingHoursData.type == 'CUSTOM' ?  workingHoursData.workingHours.map((hourData) => {
            return {
                ...hourData,
                from: convertIsoToAmPm(hourData.from),
                to: convertIsoToAmPm(hourData.to),
            };
        }) : [{
            ...workingHoursData.workingHours[0],
            from: convertIsoToAmPm(workingHoursData.workingHours[0].from),
            to: convertIsoToAmPm(workingHoursData.workingHours[0].to),
        }]
    }

    const scheduleGroup = [{
        callback: handleScheduleTypeChange,
        width: 200,
        items: [
            {
                key: 'EVERYDAY',
                label: 'Everyday',
                value: 'EVERYDAY',
            },
            {
                key: 'WEEKDAYS',
                label: 'Weekdays',
                value: 'WEEKDAYS',
            },
            {
                key: 'CUSTOM',
                label: 'Custom',
                value: 'CUSTOM',
            }
        ],
    }];
    const getDaysHoursSettings = (type: string):WorkingHoursInterface => {
        switch (type) {
            case 'EVERYDAY':
                return (
                    {
                        type: 'EVERYDAY',
                        timeZone: formData.timeZone,
                        enabled: true,
                        workingHours: [
                            {
                                from: '09:00 AM',
                                to: '17:00 PM',
                                enabled: true,
                                day: 'MONDAY',
                            }
                        ]
                    }
                )
                break;
            case 'WEEKDAYS':
                return (
                    {
                        type: 'WEEKDAYS',
                        enabled: true,
                        timeZone: formData.timeZone,
                        workingHours: [
                            {
                                from: '09:00 AM',
                                to: '17:00 PM',
                                enabled: true,
                                day: 'MONDAY',
                            }]

                    })
                break;
            case 'CUSTOM':
                return (
                    {
                        type: 'CUSTOM',
                        enabled: true,
                        timeZone: formData.timeZone,
                        workingHours: [
                            {
                                from: '09:00 AM',
                                to: '17:00 PM',
                                enabled: true,
                                day: 'MONDAY'
                            },
                            {
                                from: '09:00 AM',
                                to: '17:00 PM',
                                enabled: true,
                                day: 'TUESDAY'
                            },
                            {
                                from: '09:00 AM',
                                to: '17:00 PM',
                                enabled: true,
                                day: 'WEDNESDAY'
                            },
                            {
                                from: '09:00 AM',
                                to: '17:00 PM',
                                enabled: true,
                                day: 'THURSDAY'
                            },
                            {
                                from: '09:00 AM',
                                to: '17:00 PM',
                                enabled: true,
                                day: 'FRIDAY'
                            },
                            {
                                from: '09:00 AM',
                                to: '17:00 PM',
                                enabled: true,
                                day: 'SATURDAY'
                            },
                            {
                                from: '09:00 AM',
                                to: '17:00 PM',
                                enabled: true,
                                day: 'SUNDAY'
                            }
                        ]
                    }
                )
                break;
            default:
                return (
                    {
                        type: 'EVERYDAY',
                        timeZone: formData.timeZone,
                        enabled: true,
                        workingHours: [
                            {
                                from: '09:00 AM',
                                to: '17:00 PM',
                                enabled: true,
                                day: 'MONDAY',
                            }
                        ]
                    }
                )
        }
    }

    const handeDayToggle = (index: number) => {
        formData.workingHours[index].enabled = !formData.workingHours[index].enabled;
    }
    const convertTo24HourFormat = (time: string): { hour: number, minute: number } => {
        const [timePart, period] = time.split(' ');
        const [hours, minutes] = timePart.split(':').map(Number);

        let hour = hours % 12;
        if (period.toLowerCase() === 'pm') {
            hour += 12;
        }

        return { hour, minute: minutes };
    };

    const validateTime = (from: string, to: string): string | null => {
        const { hour: fromHour, minute: fromMinute } = convertTo24HourFormat(from);
        const { hour: toHour, minute: toMinute } = convertTo24HourFormat(to);

        if (fromHour > toHour || (fromHour === toHour && fromMinute >= toMinute)) {
            return '"From" time should be earlier than "to" time';
        }

        return null;
    };

    const handleHoursChange = (value: string, index: number, timeType: 'from' | 'to') => {
        const updatedHours = formData.workingHours.map((hourData, idx) => {
            if (idx === index) {
                return {
                    ...hourData,
                    [timeType]: value,
                };
            }
            return hourData;
        });

        const currentData = updatedHours[index];
        const error = validateTime(currentData.from, currentData.to);

        const updatedErrors = [...errors];
        if (error) {
            updatedErrors[index] = error;
        } else {
            updatedErrors.splice(index, 1);
        }

        setFormData({...formData, workingHours: updatedHours});
        setErrors(updatedErrors);
    };


    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const data = {
            ...formData,
            timeZone: formData.timeZone.value,
            workingHours: formData.workingHours.map((hourData) => {
                return {
                    ...hourData,
                    from: convertAmPmToIso(hourData.from, formData.timeZone.value),
                    to: convertAmPmToIso(hourData.to, formData.timeZone.value),
                };
            })
        }


        const hasFieldErrors = errors.length > 0;

        if (hasFieldErrors) {
            return;
        }


        await dispatch(updateMsisdnSettingsWorkingHours({id, data}));
        navigate('/phoneNumbers');
    };

    return (
        <>
            {Object.keys(formData).length && (
                <div className='w-full '>

        <div className={`${isMobile ? ' px-4' : isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
            <div className='h-[calc(100vh-275px)] overflow-x-hidden  overflow-y-scroll mr-8'>
                        <div className="flex flex-col border border-black  m-8 gap-6 ">
                            <div className='border-b border-black'>
                                <div className='font-semibold px-6 pt-6 text-lg'>
                                    Set working hours
                                </div>
                                <div className='text-grey-500 px-6 pb-6 text-sm'>
                                    Control how your phone numbers works at different times of day
                                </div>
                            </div>
                            <div className='px-6 gap-6 grid'>
                                <ChSearch
                                    value={formData?.timeZone.label }
                                    onChange={(value: any) => setFormData({...formData, timeZone: value})}
                                    placeholder={'Search'} type={'text'} disabled={false} fit prefixIcon='search'
                                    items={timezoneList} label='Timezone'/>
                            </div>
                        </div>
                        <div className="flex flex-col border border-black  m-8  ">
                            <div className='border-b border-black'>
                                <div className='flex justify-between  items-center px-6 pt-6'>
                                    <div className='font-semibold  text-lg'>
                                        Schedule
                                    </div>
                                    <Switch size='lg' checked={formData.enabled} callback={() => setFormData({...formData, enabled: !formData.enabled})}  alignLeft={true}></Switch>

                                </div>
                                <div className='text-grey-500 px-6 pb-6 text-sm items-center'>
                                    Set the schedule. Calls within the hours are sent to the users assigned to this
                                    phone
                                    number.
                                </div>
                            </div>
                            <div
                                className={`justify-between items-start p-6 gap-6 grid ${formData?.type !== 'Custom' && '!flex'} + ${isMobile ? ' flex' : ' '}`}>
                                <ChDropdown selected={[capitalize(formData?.type)]} dropdownGroup={scheduleGroup}
                                            width={210}></ChDropdown>
                                {Boolean(formData?.type !== 'CUSTOM') &&
                                   formData?.workingHours.map((_, index) => {
                                            return (
                                                <>
                                                    <div>
                                                        <div className={'flex gap-24'}>

                                                            <div className='flex gap-8 items-center'>
                                                                from: <div><Timepicker
                                                                error={errors[index]}
                                                                value={formData.workingHours[index].from}
                                                                onTimeChange={(value: string) => handleHoursChange(value, index, 'from')}></Timepicker>
                                                            </div>
                                                                to: <div>
                                                                <Timepicker
                                                                    error={errors[index]}
                                                                    value={formData.workingHours[index].to}
                                                                    onTimeChange={(value: string) => handleHoursChange(value, index, 'to')}></Timepicker>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        {errors[index] && <div className='text-red-500 text-center ml-14'>{errors[index]}</div>}

                                                    </div>
                                                </>
                                            )
                                        }
                                    )
                                }

                            </div>
                            <div>
                                {Boolean(formData?.type == 'CUSTOM') &&
                                    formData?.workingHours.map((hour, index:any) => {

                                        return (
                                            <>
                                                <div className={'flex gap-24  m-8'}>

                                                        <div className='w-[180px]'>
                                                            {Boolean(formData?.type == 'CUSTOM') &&
                                                                <Switch size='lg' checked={hour.enabled}
                                                                        callback={() => handeDayToggle(index)}
                                                                        label={hour?.day}
                                                                        alignLeft={true}></Switch>
                                                            }
                                                        </div>
                                                    <div>
                                                        <div className='flex gap-8 items-center'>
                                                            from: <div>
                                                            <Timepicker
                                                                error={errors[index]}
                                                                value={formData.workingHours[index].from}
                                                                onTimeChange={(value: string) => handleHoursChange(value, index, 'from')}></Timepicker>
                                                        </div>
                                                            to: <div><Timepicker
                                                            error={errors[index]}
                                                            value={formData.workingHours[index].to}
                                                            onTimeChange={(value: string) => handleHoursChange(value, index, 'to')}></Timepicker>
                                                        </div>
                                                        </div>
                                                        {errors[index] &&
                                                            <div className='text-red-500 text-center ml-14'>{errors[index]}</div>}
                                                    </div>




                                                </div>

                                            </>
                                        )
                                    })

                                }
                            </div>
                            <div>
                                {Boolean(formData?.type !== 'CUSTOM') &&
                                    formData?.workingHours.map((hour, index) => {
                                        return (
                                            <div className='mx-8 mb-8' key={index}>
                                                {weekDays.map((day) => {
                                                    const isWeekend = day === 'Saturday' || day === 'Sunday';
                                                    return (
                                                        <div className='flex gap-8 items-center mb-5' key={day}>
                                                            <div className='w-[90px] items-center'>
                                                                {day}
                                                            </div>
                                                            <div className='font-semibold'>
                                                                {isWeekend && formData?.type == 'WEEKDAYS' ? (
                                                                  <div className='font-normal'> Not working on this day</div>
                                                                ) : (
                                                                    hour.enabled ? (
                                                                        `${formData.workingHours[index].from} - ${formData.workingHours[index].to}`
                                                                    ) : (
                                                                        'Not working on this day'
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}

                            </div>
                        </div>
                        <div className={`mx-8 mt-8 flex justify-end`}>
                            <div className='flex gap-3 justify-end m-8'>
                                <Link to={'/phoneNumbers'}><Button buttonType='secondary' size='large'
                                                                   label={'Cancel'}></Button></Link>
                                <Button size='large' onClick={(e) => handleSubmit(e)} label={'Done'}></Button>
                            </div>
                        </div>
                    </div>

                </div>
                </div>
            )}
        </>
    );
};
export default WorkingHours;
