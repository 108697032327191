import {Call} from "../../store/features/calls/callsSlice";
import {Avatar, Button, ChColumn} from "ch3-ui-lib";
import {timeSince} from "../../utilis/timeUtilis";
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {Colors} from "../../utilis/types/Types";
import {FlagImage} from "react-international-phone";
import {getFlag, getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import {Link} from "react-router-dom";
import {hexToEmoji} from "../../components/Emoji/emoji";


interface InboundCallQueueProps {
    callQueue: Call[];
    isMobile: boolean;
}

const InboundCallQueue = ({callQueue}: InboundCallQueueProps) => {
    if (!callQueue) return null;

    const [callsQueue, setData] = useState(callQueue);

    useEffect(
        () => {
            console.log('callQueue', callQueue);
            setData(callQueue);
        },
        [callQueue]
    );


    const intl = useIntl();
    const name = intl.formatMessage({id: 'calls.inboundQueue.name'});
    const phoneNumber = intl.formatMessage({id: 'calls.inboundQueue.phone-number'});
    const queueTime = intl.formatMessage({id: 'calls.inboundQueue.queue-time'});
    // const action = intl.formatMessage({id: 'calls.inboundQueue.action'});

    useEffect(() => {

        type TimeDifferences = {
            [key: string]: string;
        };
        const intervalId = setInterval(() => {
            const updatedTimes: TimeDifferences = {};
            callsQueue.forEach(call => {
                updatedTimes[call.callId] = timeSince(call.startedAt);
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, [callsQueue]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setData((prevQueue) => {
                return prevQueue.map(call => {
                    const updatedCall = { ...call, timeSinceStarted: timeSince(call.startedAt) };
                    return updatedCall;
                });
            });
        }, 1000);

        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, [callsQueue]);

    useEffect(() => {
        setData(callQueue);
    }, [callQueue]);

    const handleDataChange = (newData: any) => {
        setData(newData);
    };
    return (
        <div className='border-x border-t border-black mr-8 '>
            <p className='text-lg font-semibold px-4 py-5 border-b border-black'>
                <FormattedMessage id="calls.inboundQueue" defaultMessage="Inbound call queue"
                                  description="Inbound call queue header"/>
            </p>
            <table className='w-full'>
                <thead>
                {   callsQueue.length != 0 && <tr className='border-b border-black'>
                    <th className='pl-4 py-3' onClick={() => handleDataChange([...callQueue])}
                    ><ChColumn propertyName='fullName' label={name}/></th>
                    <th onClick={() => handleDataChange([...callQueue])}
                    ><ChColumn propertyName='msisdn' label={phoneNumber} /></th>
                    <th onClick={() => handleDataChange([...callQueue])}
                    ><ChColumn propertyName='startedAt' label={queueTime}/></th>
                    {/*<th onClick={() => handleDataChange([...callQueue])}*/}
                    {/*><span className={' font-semibold flex items-center text-xs'}>{action}</span></th>*/}
                </tr>}
                    </thead>
                <tbody>
                {callsQueue.map((call, index) => {
                    return (
                        <tr className='border-y border-black' key={index}>
                            <td className='flex gap-3  items-center pl-4 py-4'>
                                <div>
                                    <div className='flex items-center pl-4 py-4 '>
                                        {Boolean(call.primaryContact) &&
                                            <Avatar size="md" type={call.primaryContact ? 'initials' : 'default'}
                                                    color={call.primaryContact?.iconColor ?? Colors.auto}
                                                    name={call.primaryContact ? (call.primaryContact.firstName + ' ' + call.primaryContact.lastName) : ''}/>
                                        }
                                        {Boolean(call.internalCallee) &&
                                            <Avatar size="md" type={call.internalCallee ? 'initials' : 'default'}
                                                    color={call.internalCallee?.iconColor ?? Colors.auto}
                                                    name={call.internalCallee ? (call.internalCallee.firstName + ' ' + call.internalCallee.lastName) : ''}/>
                                        }
                                        {Boolean(!call.internalCallee && call.primaryContact) &&
                                            <p className='pl-3'>{call.primaryContact?.firstName + ' ' + call.primaryContact?.lastName} </p>
                                        }
                                        {Boolean(!call.internalCallee && !call.primaryContact) &&
                                            <>
                                                <Avatar size="sm" type={'default'} color={Colors.auto}/>
                                                <div className='flex'>
                                                    <FlagImage size={16} className='w-3 h-3 ml-3'
                                                               iso2={getFlag(call.clientMsisdn) ?? 'us'}/>
                                                    <div
                                                        className='ml-3'>{getFormatedPhoneNumber(call?.clientMsisdn, true)}</div>
                                                </div>

                                            </>

                                        }
                                        <p className='pl-3'>{call.internalCallee && call.internalCallee.firstName + ' ' + call.internalCallee.lastName}</p>
                                    </div>
                                    {Boolean(!call.internalCallee && !call.primaryContact) &&
                                        <Link to={`../contacts/new?phoneNumber=${call.clientMsisdn}`}>
                                            <Button size={'small'} className={'pl-4 pb-4 pt-0'}
                                                    leftIcon='person_add_alt' buttonType={'textPrimary'}
                                                    label={'Add contact'}/>

                                        </Link>
                                    }
                                </div>
                            </td>
                            <td >

                                {call.exposedMsisdn && <div className='flex'>
                                    <div
                                        className="text-[24px] flex gap-4">{hexToEmoji(call.exposedMsisdn.icon)}</div>
                                    <p className=' ml-2 w-[200px] truncate'>{call.exposedMsisdn.label ? call.exposedMsisdn.label : getFormatedPhoneNumber(call.exposedMsisdn.msisdn)}</p>
                                </div>}

                            </td>
                            <td className='text-start'>
                                   <p>{timeSince(call.startedAt)}</p>
                            </td>
                            {/*<td>*/}
                            {/*    <Icon iconName='arrow_forward'/>*/}
                            {/*</td>*/}
                        </tr>
                    )
                }
                )}

                </tbody>
                {
                    callsQueue.length === 0 &&
                    <div className='flex text-center justify-center border-b border-black'>
                        <div className='w-full  m-14'>
                            <div className='flex  justify-center '>
                                {/*<img className='m-4' src={listEmpty}/>*/}
                            </div>

                            <h1 className='font-bold'>
                                No calls in queue!
                            </h1>
                            <div className='mb-6 mt-2'>
                                There are currently no calls waiting in the queue.
                            </div>
                        </div>

                    </div>


                }
            </table>
        </div>
    );
};
export default InboundCallQueue;



