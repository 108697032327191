
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { getApiUrl } from '../../../config'
import axios from 'axios';
import {showToast} from "../Notifications/notificationsSlice.tsx";
import {Group} from "../groups/groupsSlice";
import {Msisdn} from "../Msisdns/msisdnsSlice";

export interface User {
    userId: number;
    email: string;
    firstName: string;
    lastName: string;
}

const getHeaders = () => ({
    'Content-Type': 'application/json',
    'tenant': localStorage.getItem('tenant'),
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
});

export const fetchUserProfile = createAsyncThunk('users/fetchUserProfile', async () => {
    const userId = localStorage.getItem('userId');
    const response = await axios.get(`${await getApiUrl()}/user/profile/${userId}`, { headers: getHeaders() });
    return response.data;
});

export const fetchUserProfileNotifications = createAsyncThunk('users/fetchUserProfileNotifications', async () => {
    const userId = localStorage.getItem('userId');
    const response = await axios.get(`${await getApiUrl()}/user/profile/${userId}/notifications`, { headers: getHeaders() });
    return response.data;
});

export const updateProfile = createAsyncThunk('users/updateUserProfile', async (data : {userId: string | null, firstName: string; lastName: string }, thunkAPI) => {
        try {
            await axios.patch(`${await getApiUrl()}/user/profile/${data.userId}`, data, {
                headers: getHeaders()
            });
            thunkAPI.dispatch(showToast({ description: 'Profile updated successfully!', type: 'success' }));
        } catch (error) {
            console.log(error);
            thunkAPI.dispatch(showToast({ description: 'Failed to update profile!', type: 'error' }));
            throw error;
        }
        return data;

});

export const updateMissedCallEmailNotificationEnabled = createAsyncThunk('users/updateUserProfileNotifications', async ({ userId, missedCallEmailNotificationEnabled }: { userId: string; missedCallEmailNotificationEnabled: boolean}, thunkAPI) => {

    const data = {
        userId,
        missedCallEmailNotificationEnabled
    };

    try {
        await axios.patch(`${await getApiUrl()}/user/profile/${userId}/notifications`, data, {
            headers: getHeaders()
        });
        thunkAPI.dispatch(showToast({ description: 'Notifications state updated successfully!', type: 'success' }));
    } catch (error) {
        thunkAPI.dispatch(showToast({ description: 'Failed to change notifications state!', type: 'error' }));
        throw error;
    }
    return data;
});

interface UserSettings {
    // userId: number,
    firstName: string,
    lastName: string
    email: string,
    missedCallEmailNotificationEnabled: boolean,
    groups: Group[],
    msisdns: Msisdn[],
}

const initialState: UserSettings = {
    firstName: '',
    lastName: '',
    email: '',
    missedCallEmailNotificationEnabled: false,
    groups: [],
    msisdns: [],
}

const profileSettingsSlice = createSlice({
    name: 'msisdnSettings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                fetchUserProfile.fulfilled, (state, action) => {
                    state.firstName = action.payload.firstName
                    state.lastName = action.payload.lastName
                    state.email = action.payload.email
                    state.groups = action.payload.groups
                    state.msisdns = action.payload.msisdns
                })
            .addCase(
                fetchUserProfileNotifications.fulfilled, (state, action) => {
                    state.missedCallEmailNotificationEnabled = action.payload.missedCallEmailNotificationEnabled
                })
    },
});

export const selectProfileSettingsGeneral = (state: any) => state.profileSettings;

export default profileSettingsSlice.reducer;




