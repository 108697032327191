import {getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import {RootState, useAppDispatch} from "../../store/store";
import {
    getSelectedSpeaker,
    setCallFrom,
    setCallState,
} from "../../store/features/calls/dialerSlice";
import {Icon} from "ch3-ui-lib";
import {call} from "../Dialer/services/sipService";
import {useSelector} from "react-redux";
import {useCallOptions} from "../Dialer/hooks/useCallOptions";

interface ClickToCallProps {
    label?: string | undefined,
    msisdn: number
    noPrefix?: boolean
    className?: string
    icon?: boolean
    internal?: boolean
}

export const ClickToCall = ({label, msisdn, noPrefix, className, icon = false, internal = false}: ClickToCallProps) => {

    const audioDevice = useSelector(getSelectedSpeaker);
    const options = useCallOptions();
    const callState = useSelector((state: RootState) => state.dialer.callState);

    const setupCall = (e: any) => {
        {
            if (callState !== 'IDLE') return;
            call(msisdn.toString(), options, audioDevice);

            dispatch(setCallState('CONNECTING'))
            e.preventDefault()
            e.stopPropagation();
        }
    }
    const internalCall = (e: any) => {
        if(callState !== 'IDLE') return;
        const currentUserId = localStorage.getItem('userId');
        dispatch(setCallFrom(label));
        call(msisdn.toString(), {
            ...options,
            fromUserName: currentUserId
        }, audioDevice);
        dispatch(setCallState('CONNECTING'))
        e.preventDefault()
        e.stopPropagation();
    }

    const dispatch = useAppDispatch();
    return (
        <div onClick={(e: any) => (internal ? internalCall(e) : setupCall(e))}
             className={`flex items-center hover:text-primary-500 cursor-pointer ${className}`}>{label ? label : getFormatedPhoneNumber(msisdn, noPrefix)}
            <div>
                {icon && <Icon className=' hover:text-primary-500 cursor-pointer' iconName={'call'} size='sm'/>}
            </div>
        </div>
    )
}
