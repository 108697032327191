import {useSelector} from "react-redux";
import {CallStateEnum, fetchCall} from "../../store/features/calls/callsSlice";
import {Link, useParams} from "react-router-dom";
import {RootState, useAppDispatch} from "../../store/store";
import {useEffect} from "react";
import Header from "../../components/header/Header";
import {DateFormatter, duration} from "../../utilis/timeUtilis";
import {FlagImage} from "react-international-phone";
import {getFlag, getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import {hexToEmoji} from "../../components/Emoji/emoji";
import {Avatar, Button} from "ch3-ui-lib"
import {Colors} from "../../utilis/types/Types";
import {getCallDescription} from "../../utilis/callsUtilis";
import {ClosedDialerMargin, OpenDialerMargin} from "../../utilis/dialerWidthUtils.ts";
import {ClickToCall} from "../../components/ClickToCall/ClickToCall";
import { useTheme } from "next-themes";


const CallDetails = () => {
    const callId = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const callDetails = useSelector((state: RootState) => state.calls.call);
    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);

    const { theme } = useTheme();



    useEffect(() => {
            const fetchData = async () => {
                await dispatch(fetchCall(Number(callId.id)));

            };
            fetchData();
        }, [dispatch, callId]
    );
    return (
        callDetails &&


        <div className='w-full'>
            <Header header='Call details' icon={'1F919'}/>
            <div className='ml-8 mb-8'>
                <Link className='flex gap-4 items-center' to={'/calls'}>
                    <Button size='large' label='Back' className={`gap-4 pl-0 ${theme === 'dark' ? ' text-white' : ' text-black'}` } buttonType='textSecondary' leftIcon='arrow_back_ios'/>
                </Link>
            </div>
            <div className='mx-8'>
            <div
                className={`ml-8 flex flex-col items-center self-stretch   border border-solid ${isDialerHidden ? ClosedDialerMargin : OpenDialerMargin} ${theme === 'dark' ? ' border-white' : ' border-black'}` }>
                <header
                    className={`w-full flex flex-col justify-center self-stretch w-full  border-b  border-solid max-md:max-w-full ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                    <div
                        className="w-full flex gap-4 justify-between px-4 py-5 w-full max-md:flex-wrap max-md:max-w-full">
                        <h1 className="text-base font-medium leading-7 ">{getCallDescription(callDetails)}</h1>
                        <time className="flex gap-2 justify-center my-auto text-sm leading-5 text-slate-900"
                              dateTime={callDetails.startedAt}>
                            <img loading="lazy"
                                 src="https://cdn.builder.io/api/v1/image/assets/TEMP/e37d259e211ceb16aed3a27815c93ef375257621ec36baec505330c908f61d4f?apiKey=b80f1a000b1b49588dd2774515b82ddf&"
                                 alt="Clock icon" className="shrink-0 w-5 aspect-square"/>
                            <span>{Boolean(callDetails.startedAt) && DateFormatter(callDetails.startedAt)}</span>
                        </time>
                    </div>
                </header>
                <div className={'w-full px-4 pb-5'}>


                <div
                    className={`flex gap-5 justify-between px-5 py-3.5 w-full text-sm font-semibold leading-5 border-b border-dashed max-md:flex-wrap max-md:max-w-full ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                    <div className="text-zinc-600 items-center flex">{callDetails.type == CallStateEnum.INBOUND ? 'Caller' : 'Recipient'} </div>
                    <div className={'flex gap-4'} >

                        <div className={'text-right items-end flex flex-col'}>
                            {!callDetails.internalCallee && <div>
                                {callDetails.primaryContact && <div>

                                    {callDetails.primaryContact.firstName + ' ' + callDetails.primaryContact.lastName}
                                </div>}
                                <div className={`flex gap-3  items-center`}>
                                    {callDetails.clientMsisdn && <><FlagImage className='w-4 h-4'
                                                                       iso2={getFlag(callDetails.clientMsisdn) ?? 'us'}/>
                                        <ClickToCall noPrefix={true} msisdn={callDetails.clientMsisdn}  /> </>}

                                        </div>

                            </div>}
                            {callDetails.internalCallee && <div>

                               <div className='flex gap-3 items-center'>
                                   <Avatar size="md" type='initials' color={callDetails.internalCallee.iconColor ?? Colors.auto}
                                           name=   {callDetails.internalCallee.firstName + ' ' + callDetails.internalCallee.lastName}/>
                                   <ClickToCall internal={true} noPrefix={true} msisdn={callDetails.internalCallee.userId} label= {callDetails.internalCallee.firstName + ' ' + callDetails.internalCallee.lastName} />

                                </div>


                            </div>}
                            {!callDetails.primaryContact  && !callDetails.internalCallee  &&
                                                            <div className='flex justify-end mt-4'>
                                                                <Link to={`../contacts/new?phoneNumber=${callDetails.clientMsisdn}`}>
                                                            <Button  className={'p-0'} size='small' buttonType='textPrimary'
                                                                    label='Add contact'
                                                                    leftIcon='person_add_alt'/>
                                                                    </Link>

                                        </div>
                                    }

                                </div>

                        <>
                            {callDetails.primaryContact && <Avatar size="md" type='initials' color={callDetails.primaryContact.iconColor ?? Colors.auto} name={callDetails.primaryContact.firstName + ' ' + callDetails.primaryContact.lastName} icon='star'/>}

                                </>
                            </div>
                        </div>

                {(callDetails.bridgetAt && callDetails.finishedAt) && <div
                    className={`flex gap-5 justify-between px-5 py-3.5 w-full text-sm font-semibold leading-5 border-b  border-dashed max-md:flex-wrap max-md:max-w-full ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                    <div className="text-zinc-600 items-center">Call length</div>
                    <div className={`flex gap-3 text-center text-black items-center`}>
                        {duration(callDetails.bridgetAt, callDetails.finishedAt)}
                    </div>
                </div>}
                {Boolean(callDetails.bridgetAt || callDetails.type !== 'INBOUND') && <>
                    <div

                        className={`flex gap-5 justify-between px-5 py-3.5 w-full text-sm font-semibold leading-5 border-black max-md:flex-wrap max-md:max-w-full ${callDetails?.exposedMsisdn ? ' border-b  border-dashed' : ''}`}>

                        <div className="text-zinc-600 items-center">User</div>
                        <div>{callDetails.user.firstName + ' ' + callDetails.user.lastName }</div>
                    </div>

                </>}
                    {callDetails?.exposedMsisdn && <div
                        className="flex gap-5 justify-between px-5 py-3.5 w-full text-sm font-semibold leading-5  border-black border-dashed max-md:flex-wrap max-md:max-w-full">
                        <div className="text-zinc-600 items-center">Phone number</div>
                        {callDetails?.exposedMsisdn && <div className='flex'>
                            <div className="text-[16px] mr-2">{hexToEmoji(callDetails?.exposedMsisdn.icon)} </div>
                            <div>{callDetails?.exposedMsisdn.label ? callDetails?.exposedMsisdn.label : getFormatedPhoneNumber(callDetails?.exposedMsisdn.msisdn)}</div>
                        </div>}

                        </div>}

                    </div>

            </div>

            </div>
        </div>

    )
};

export default CallDetails;
