import {useIntl} from "react-intl";
import {Call} from "../store/features/calls/callsSlice";
import moment from 'moment-timezone';

export const  formatDate = (date: Date) => {
    const intl = useIntl();
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
        return intl.formatMessage({ id: 'time.today' });
    } else if (date.toDateString() === yesterday.toDateString()) {
        return intl.formatMessage({ id: 'time.yesterday' });
    } else {
        const options: Intl.DateTimeFormatOptions = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }
}
export const DateFormatter = ( dateString: string, noTime?: boolean ) => {

    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
    const timeString = date.toLocaleTimeString(undefined, options).toLowerCase();
    const dateStringFormatted = date.toLocaleDateString();

    if (noTime) {
        return dateStringFormatted;
    }
    const formattedDate = `${timeString}, ${dateStringFormatted}`;

    return formattedDate
};
export const formatDateDayMonth = (date: Date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const options: Intl.DateTimeFormatOptions = {weekday: 'long', month: 'long', day: 'numeric', year: 'numeric'};
    return date.toLocaleDateString('en-US', options);
}
export function getTimeAgo(finishedAt: Date | null) {
    if (!finishedAt) return ''; // Handle the case when finishedAt is null

    const finishedDate = new Date(finishedAt);
    const now = new Date();
    const diffMilliseconds = now.getTime() - finishedDate.getTime();
    const diffMinutes = Math.floor(diffMilliseconds / (1000 * 60));


    if (diffMinutes < 60) {
        return `${diffMinutes} minutes ago`;
    }

    if (diffMinutes < 720) {
        return `${Math.floor(diffMinutes / 60)} hours ago`;
    } else {
        const locale = navigator.language;
        const options:Intl.DateTimeFormatOptions = {
            hour: '2-digit',
            minute: '2-digit'
        };
        return finishedDate.toLocaleTimeString(locale, options);
    }
}
export function groupCallsByDate(calls: Call[]) {
    if (!calls) return;

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const groups: { [key: string]: Call[] } = {};

    const otherDates: Record<string, Call[]> = {};

    calls.forEach((call: Call) => {
        const callDate = new Date(call.startedAt);

        if (isSameDate(callDate, today)) {
            if (!groups['Today']) {
                groups['Today'] = [];
            }
            groups['Today'].push(call);
        } else if (isSameDate(callDate, yesterday)) {
            if (!groups['Yesterday']) {
                groups['Yesterday'] = [];
            }
            groups['Yesterday'].push(call);
        } else {
            const dateKey = formatDate(callDate);
            if (!otherDates[dateKey]) {
                otherDates[dateKey] = [];
            }
            otherDates[dateKey].push(call);
        }
    });

    Object.values<Call[]>(groups).forEach((group: Call[]) => {
        group.sort((a: Call, b: Call) => new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime());
    });

    Object.values<Call[]>(otherDates).forEach((group: Call[]) => {
        group.sort((a: Call, b: Call) => new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime());
    });


    const nonEmptyGroups = Object.fromEntries(
        Object.entries(groups).filter(([, calls]) => calls && calls.length > 0)
    );

    return { ...nonEmptyGroups, ...otherDates };
}

function isSameDate(date1: Date, date2:Date) {
    return date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear();
}

export const formattedTime = (timer:number) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};
export const duration = (startedAt: string, finishedAt: string) => {
    const startedDate = new Date(startedAt);
    const finishedDate = new Date(finishedAt);
    const diff = finishedDate.getTime() - startedDate.getTime();
    const minutes = Math.floor(diff / 60000);
    const seconds = ((diff % 60000) / 1000).toFixed(0);
    return `${minutes ? minutes + ' min'  : ''}  ${parseInt(seconds) < 10 ? '0' : ''}${seconds} sec`;
}
export function timeSince(startedAt: string | number | Date) {
    const now = new Date().getTime();
    const started = new Date(startedAt).getTime();

    if (isNaN(started)) {
        console.error('Invalid date format for startedAt:', startedAt);
        return '00:00:00';
    };
    const diffInSeconds = Math.floor((now - started) / 1000);

    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    if (hours > 0) {
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    return `${formattedMinutes}:${formattedSeconds}`;
}


export function convertAmPmToIso(timeString: string, timeZone: string): string {

    const time24 = moment(timeString, ['h:mm A']).format('HH:mm');
    const currentDate = moment.tz(timeZone).format('YYYY-MM-DD');


    const timeInZone = moment.tz(`${currentDate} ${time24}`, 'YYYY-MM-DD HH:mm', timeZone);
    const isoTime = timeInZone.format('HH:mmZ');

    return isoTime;
}


export function convertIsoToAmPm(timeString: string): string {

    const isoTime = `1970-01-01T${timeString}`;

    const date = moment.parseZone(isoTime);
    if (!date.isValid()) {
        throw new Error('Invalid time format');
    }

    const formattedTime = date.format('h:mm A');
    return formattedTime;
}

export function convertToISOFormat(dateString: string, timeString: string) {

    const combinedDateTimeString = `${dateString} ${timeString}`;
    const momentDate = moment(combinedDateTimeString, "MMM DD, YYYY h:mm A");

    const isoStringWithOffset = momentDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ");

    return isoStringWithOffset;
}

export function convertFromISOFormat(isoString: string) {
    if(!isoString) return { dateString: '', timeString: '' };
    const momentDate = moment(isoString);
    const currentDate = moment();

    if (momentDate.isBefore(currentDate, 'day')) {
        return { dateString: '', timeString: '' };
    }

    const isToday = momentDate.isSame(currentDate, 'day');

    const dateString = isToday ? '' : momentDate.format('MMM DD, YYYY');
    const timeString = momentDate.format('h:mm A');

    return { dateString, timeString };
}

export const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
