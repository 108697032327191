import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {ColorKey} from "../../../utilis/types/Types";
import {getApiUrl} from "../../../config";

export enum CallStateEnum {
    INBOUND = 'INBOUND',
    OUTBOUND = 'OUTBOUND',
}
export type CallState = keyof typeof CallStateEnum
export interface Call {
    callId: number;
    primaryContact: {
        contactId: number;
        firstName: string;
        lastName: string;
        iconColor: ColorKey;
        availability: string;
    };
    duplicatedContacts: {
        contactId: number;
        firstName: string;
        lastName: string;
        availability: string;
    }[];
    type: CallState;
    clientMsisdn: number;
    internalCallee: any
    exposedMsisdn: {
        msisdn: number;
        label: string;
        type: string;
        icon: string;
    };
    startedAt: string;
    bridgetAt: string;
    finishedAt: string;
    user: {
        userId: number;
        firstName: string;
        lastName: string;
    },
    forwardedTo: number;
    forwardedAt: string;
}
interface CallList {
    calls: Call[];
    totalCount: number;
}
interface CallsState {
    inboundQueueCall: Call[];
    ongoingCall: Call[];
    historyList: Call[];
    totalCount: number;
    contactHistory: Call[];
    call: Call | null;
    loading: boolean;
    status: string;
    error: string | undefined;

}
// Define initial state
const initialState: CallsState = {
    inboundQueueCall: [],
    ongoingCall: [],
    historyList: [],
    totalCount: 0,
    contactHistory: [],
    call: null,
    loading: false,
    status: 'idle',
    error: undefined,
};
interface ParamsProps {
    from?: string,
    to?: string,
    user?: number,
    contacts?: number[],
    users?: number[],
    groups?: number[],
    phoneNumbers?: number[]
    limit?: number
}
const getHeaders = () => ({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'tenant': localStorage.getItem('tenant')
});

// Create async thunk for fetching historyList

export const filterParams = (params: any) => {
    return Object.fromEntries(Object.entries(params).filter(([_, v]) => (v != null && v !== '') || (Array.isArray(v) && v.length > 0)));
};

export const fetchHistoryList = createAsyncThunk<CallList,ParamsProps,{rejectValue: string}>(
    'calls/fetchHistoryList',
    async ({ from, to, contacts, users, groups, phoneNumbers,limit }, thunkAPI) => {

        const user =Number( localStorage.getItem('userId'));
        try {
            const response = await axios.get(`${await getApiUrl()}/call/list/${user}`, {
                headers: getHeaders(),
                params:filterParams({ from, to, contacts, users, groups, phoneNumbers, limit },),
                paramsSerializer: params => {
                    return Object.entries(params).map(([key, value]) => {
                        if (Array.isArray(value)) {
                            if(value.length === 0) return;
                            return `${key}=${ value.join(',')}`;
                        }
                        return `${key}=${value}`;
                    }).join('&');
                }
            });
            return {calls:response.data, totalCount: response.headers['x-total-count']};
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return thunkAPI.rejectWithValue(error.response?.data);
            } else {
                throw error;
            }
        }
    }
);

// Create async thunk for fetching contactHistory
export const fetchContactHistory = createAsyncThunk<Call[],number, {rejectValue: string}>(
    'calls/fetchContactHistory',
    async (contactId, thunkAPI) => {
        try {
            const response = await axios.get(`${await getApiUrl()}/call/contact/list/${contactId}`, {
                headers: getHeaders(),

            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return thunkAPI.rejectWithValue(error.response?.data);
            } else {
                throw error;
            }
        }
    }
);
export const fetchCall = createAsyncThunk<Call,number, {rejectValue: string}>(
    'calls/fetchCall',
    async (callId, thunkAPI) => {
        try {
            const response = await axios.get(`${await getApiUrl()}/call/${callId}`, {
                headers: getHeaders()
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return thunkAPI.rejectWithValue(error.response?.data);
            } else {
                throw error;
            }
        }
    }
);
export const fetchOngoingCall = createAsyncThunk<Call[],undefined, {rejectValue: string}>(
    'calls/fetchOngoingCall',
    async (_, thunkAPI) => {

        const user =Number( localStorage.getItem('userId'));
        try {
            const response = await axios.get(`${await getApiUrl()}/call/current/${user}`, {
                headers: getHeaders(),
                params:filterParams({ callState: 'TALKING'}),
                paramsSerializer: params => {
                    return Object.entries(params).map(([key, value]) => {
                        if (Array.isArray(value)) {
                            if(value.length === 0) return;
                            return `${key}=${ value.join(',')}`;
                        }
                        return `${key}=${value}`;
                    }).join('&');
                }
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return thunkAPI.rejectWithValue(error.response?.data);
            } else {
                throw error;
            }
        }
    }
);
export const fetchInboundQueueCall = createAsyncThunk<Call[],undefined, {rejectValue: string}>(
    'calls/fetchInboundQueueCall',
    async (_, thunkAPI) => {

        const user =Number( localStorage.getItem('userId'));
        try {
            const response = await axios.get(`${await getApiUrl()}/call/current/${user}`, {
                headers: getHeaders(),
                params:filterParams({ callState: 'STARTED'}),
                paramsSerializer: params => {
                    return Object.entries(params).map(([key, value]) => {
                        if (Array.isArray(value)) {
                            if(value.length === 0) return;
                            return `${key}=${ value.join(',')}`;
                        }
                        return `${key}=${value}`;
                    }).join('&');
                }
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return thunkAPI.rejectWithValue(error.response?.data);
            } else {
                throw error;
            }
        }
    }
);
// Create the slice
const callsSlice = createSlice({
    name: 'calls',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder
            .addCase(fetchHistoryList.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchHistoryList.fulfilled, (state, action) => {
                state.loading = false;
                state.status = 'succeeded';
                state.historyList = action.payload.calls;
                state.totalCount = action.payload.totalCount;
            })
            .addCase(fetchHistoryList.rejected, (state, action) => {
                state.loading = false;
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder
            .addCase(fetchContactHistory.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchContactHistory.fulfilled, (state, action) => {
                state.loading = false;
                state.contactHistory = action.payload;
            })
            .addCase(fetchContactHistory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchCall.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCall.fulfilled, (state, action) => {
                state.loading = false;
                state.call = action.payload;
            })
            .addCase(fetchCall.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchOngoingCall.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchOngoingCall.fulfilled, (state, action) => {
                state.loading = false;
                state.ongoingCall = action.payload;
            })
            .addCase(fetchOngoingCall.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchInboundQueueCall.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchInboundQueueCall.fulfilled, (state, action) => {
                state.loading = false;
                // save only inbound queue call
                state.inboundQueueCall = action.payload.filter(call => call.type === 'INBOUND');
            });
    },
});

// Export the reducer
export default callsSlice.reducer;
