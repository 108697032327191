import {hexToEmoji} from "../Emoji/emoji";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";


interface headerProps {
    header: string,
    icon?: string,
    button?: React.ReactNode
    avatar?: React.ReactNode
}


const Header = ({header, icon, button, avatar}:headerProps ) => {

    const isMobile = useMediaQuery('(max-width: 960px)');


    return (
        <div className={`h-[98px] flex pt-6 pb-4 border-b border-black mb-8 justify-between z-10 relative` + `${isMobile ? ' pl-4 flex-wrap' : ' pl-8 no-wrap'}`}>
            <div className='flex items-center pr-2'>

                {avatar && <div className='mr-4'>{avatar}</div>}
                {icon && <div className="text-[38px]">{hexToEmoji(icon, 'w-[38px] h-[38px] mr-4')}</div>}
                {Boolean(isMobile) && <h5 className='font-black '>{header}</h5>}
                {Boolean(!isMobile) && <h3 className='font-black '>{header}</h3>}
            </div>
            <div className='flex items-center mr-8'>
                {button && <div className='ml-auto'>{button}</div>}
            </div>
        </div>
    )

}
export default Header;
