import {Call} from "../../store/features/calls/callsSlice";
import {Colors} from "../../utilis/types/Types";
import {FlagImage} from "react-international-phone";
import {getFlag, getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import {getCallType, getCallTypeDescription} from "../../utilis/callsUtilis";
import {hexToEmoji} from "../../components/Emoji/emoji";
import {getTimeAgo} from "../../utilis/timeUtilis";
import {Avatar, Button, ChTooltip, Icon} from "ch3-ui-lib";
import {Link} from "react-router-dom";

interface CallRowProps {
    call: Call;
    isMobile: boolean;
}


const CallRow = ({ call, isMobile }: CallRowProps) => {
    return (
        <Link to={`/calls/details/${call.callId}`}>
            <div key={call.callId}
                 className={`flex justify-between border-b border-black ` + `${isMobile ? ' flex-col' : ' flex-row'} `}>
                <div>
                    <div className='flex items-center pl-4 py-4 '>
                        {Boolean(call.primaryContact) &&
                            <Avatar size="sm" type={call.primaryContact ? 'initials' : 'default'}
                                    color={call.primaryContact?.iconColor ?? Colors.auto}
                                    name={call.primaryContact ? (call.primaryContact.firstName + ' ' + call.primaryContact.lastName) : ''}/>
                        }
                        {Boolean(call.internalCallee) &&
                            <Avatar size="sm" type={call.internalCallee ? 'initials' : 'default'}
                                    color={call.internalCallee?.iconColor ?? Colors.auto}
                                    name={call.internalCallee ? (call.internalCallee.firstName + ' ' + call.internalCallee.lastName) : ''}/>
                        }
                        {Boolean(!call.internalCallee && call.primaryContact) &&
                            <p className='pl-3'>{ call.primaryContact?.firstName + ' ' + call.primaryContact?.lastName} </p>
                        }
                        {Boolean(!call.internalCallee && !call.primaryContact) &&
                            <>
                                <Avatar size="sm" type={'default'} color={Colors.auto}/>
                                <div className='flex'>
                                    <FlagImage size={16} className='w-3 h-3 ml-3'
                                               iso2={getFlag(call.clientMsisdn) ?? 'us'}/>
                                    <div className='ml-3'>{getFormatedPhoneNumber(call?.clientMsisdn, true)}</div>
                                </div>

                            </>

                        }
                        <p className='pl-3'>{ call.internalCallee && call.internalCallee.firstName + ' ' + call.internalCallee.lastName}</p>
                    </div>
                    {Boolean(!call.internalCallee && !call.primaryContact) &&
                        <Link to={`../contacts/new?phoneNumber=${call.clientMsisdn}`}>
                            <Button size={'small'} className={'pl-4 pb-4 pt-0'} leftIcon='person_add_alt' buttonType={'textPrimary'}
                                    label={'Add contact'}/>

                        </Link>
                    }
                </div>

                <div
                    className={`flex` + `${isMobile ? ' flex-col items-start px-2' : ' flex-row items-center px-0 w-1/4 min-w-[395px]'}`}>
                    <div className={'flex items-center w-full'}>
                        <div className='flex items-center w-1/2'>

                            <ChTooltip
                                alignArrow='start'
                                trigger={<div>{getCallType(call.type, call.bridgetAt, call.forwardedTo)}</div>}
                                content={getCallTypeDescription(call.type, call.bridgetAt)}
                            >

                            </ChTooltip>

                            {call.exposedMsisdn && <>
                                <div
                                    className="text-[24px]">{hexToEmoji(call.exposedMsisdn.icon)}</div>
                                <p className=' ml-2 w-[200px] truncate'>{call.exposedMsisdn.label ? call.exposedMsisdn.label : getFormatedPhoneNumber(call.exposedMsisdn.msisdn)}</p>
                            </>}
                            {
                                call.internalCallee && <>

                                    <p className=' ml-2 w-[200px] truncate'>{call.user.firstName + ' ' + call.user.lastName} </p>
                                </>
                            }


                        </div>
                        <div className='flex items-center w-1/2'>
                            <Icon className='pr-4' iconName='schedule'/>
                            <p className='pr-9'>{getTimeAgo(new Date(call.startedAt))}</p>
                        </div>
                    </div>
                </div>

            </div>
        </Link>
    );

}
export default CallRow;
