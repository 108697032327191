import {Call} from "../../store/features/calls/callsSlice";
import {Avatar, Icon} from "ch3-ui-lib";
import {getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import {getTimeAgo} from "../../utilis/timeUtilis";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import listEmpty from "../../assets/list-empty.svg";
import { useTheme } from "next-themes";

import useMediaQuery from "../../utilis/screenWidthUtils.ts";


interface MissedCallsProps {
    groupedCalls: { [key: string]: Call[] };
}
const MissedCalls = ({ groupedCalls }: MissedCallsProps) => {

    const { theme } = useTheme();

    const isMobile = useMediaQuery('(max-width: 960px)');


    if(!groupedCalls) return null;
    const sortedGroups = Object.keys(groupedCalls).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    return (

        <div className='pr-8'>
        <div className={`border  w-full border-x-black border-t-black`}>
            <p className={`text-lg font-semibold px-4 py-5 border-b  border-black`} >

                <FormattedMessage id="calls.missed" defaultMessage="Missed calls" description="Missed calls header" />
            </p>
            {sortedGroups.map(date => (
                <div key={date}>

                    <h6 className='pl-4 py-5 font-semibold'>{date}</h6>

                    {groupedCalls[date].map((call: Call) => (
                        <Link to={`/calls/details/${call.callId}`}>
                        <div key={call.callId} className={`border-b ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                            <div
                                className={`flex justify-between flex pl-4 py-4` + `${isMobile ? ' flex-col items-start' : ' flex-row items-center'} `}>
                                <div className='flex items-center'>
                                    <Avatar size="md" type={call.primaryContact ? 'initials' : 'icon'} icon='star'
                                            color={call.primaryContact?.iconColor ?? 'auto'}
                                            name={call.primaryContact ? (call.primaryContact.firstName + ' ' + call.primaryContact.lastName) : ''}/>
                                    <p className='pl-3'>{call.primaryContact ? (call.primaryContact.firstName + ' ' + call.primaryContact.lastName) : getFormatedPhoneNumber(call.exposedMsisdn.msisdn)}</p>
                                </div>

                                <div className='flex items-center  w-[300px]'>
                                    <Icon className='text-error-400' iconName='call_missed'/>
                                    <p className='pr-8'>{Boolean(call.clientMsisdn) && getFormatedPhoneNumber(call.clientMsisdn)}</p>
                                </div>

                                <div className='flex items-center'>
                                    <Icon className='pr-4' iconName='schedule'/>
                                    <p className='pr-9'>{getTimeAgo(new Date(call.startedAt))}</p>
                                </div>

                            </div>
                        </div>
                        </Link>
                    ))}
                </div>
            ))}
            {sortedGroups.length === 0 &&
            <div className='flex text-center  justify-center '>
                <div className='w-[450px]  m-14'>
                    <div className='flex  justify-center w-full'>
                        <img className='m-4' src={listEmpty}/>
                    </div>

                    <h1 className='font-bold'>
                        No Missed Calls!
                    </h1>
                    <div className='mb-6 mt-2'>
                        There are no missed calls.
                    </div>
                </div>

            </div>


            }
        </div>
        </div>
    );
};
export default MissedCalls;
